import React, { Suspense, useContext, useEffect, useState } from 'react';

import {faArrowDown} from '@fortawesome/pro-regular-svg-icons'

import * as appContext from '../../../../components/AppContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {fetchPropertiesAction} from "../../../../redux/actions";
import Checkers from "../../../../helpers/class/Checkers";

import cloneDeep from 'lodash/cloneDeep';
import Skeleton from "react-loading-skeleton";

interface PropertiesSectionProps {
  title?: string
  subtitle?: string
  limit?: number
  backgroundColor?: string
  darkMode?: number
  showAll?: number
  showAllLabel?: string
}

export default function PropertiesSection ({ title, subtitle, limit, backgroundColor, darkMode, showAll, showAllLabel } : PropertiesSectionProps) {

  const dispatch = useDispatch()

  const [properties, setProperties] = useState([])

  const propertiesData = useSelector(state => state['propertiesData']);

  const propertiesParams = useSelector(state => state['propertiesParams']);

  const propertiesFetchHandler = () => {
    let params = cloneDeep(propertiesParams)
    params.limit = limit
    params.is_featured = 1

    dispatch<any>(fetchPropertiesAction(params))
  }

  const propertyItemClass = (count : number) => {
    if (count % 4 == 0 && count > 0) {
      return 'col-md-6'
    }
    if (count % 2 == 0) {
      return 'col-md-6'
    }
    return 'col-md-4'
  }

  const textColor = () => {
    if (darkMode == 1) {
      return "text-light-color"
    }

    return ""
  }

  useEffect( () => {
    propertiesFetchHandler()
  }, [])

  const getItemUrl = (property) => {
    return Checkers.isValidString(property.sourcePath) ? property.sourcePath : (Checkers.isValidString(property.urlSlug) ? property.urlSlug : '/property/' + property.property_id )
  }

  useEffect(() => {
    if (propertiesData !=undefined && propertiesData.properties != undefined && Checkers.isValidArray(propertiesData.properties)) {
      setProperties(propertiesData.properties)
    }
  }, [propertiesData]);

  return (
      <>
        {
          <>
            {
              Checkers.isValidString(title) && Checkers.isValidString(subtitle) ?
                  <>
                    <section className={" p-b-20 p-t-20"} style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-sm-8 offset-sm-2">
                            <h6 className={textColor() + " block-title m-b-0"}>{subtitle}</h6>
                            <h1 className={textColor() + " m-t-5 light"}>{title}</h1>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <></>
            }
            {
              propertiesData !=undefined && properties != undefined && Checkers.isValidArray(properties) ?
                  <>
                    <section className="container-fluid b-t b-white p-0" style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="row m-auto">
                        {
                          properties.map( (property, index) => {
                            if (index>(limit-1)) {
                              return (
                                  <></>
                              )
                            }

                            return (
                                <>
                                  <a href={ getItemUrl(property) }
                                     className={ propertyItemClass(properties.length) + " text-center bg-success hover-push demo-story-block properties_div"}
                                     style={{height: "350px", cursor: "pointer", border: "1px white solid"}}>

                                    <div className="hover-backdrop"
                                         style={{backgroundImage: 'url('+property.featured_images[0]+')'}}
                                    ></div>
                                    <div className="hover-caption bottom-left bottom-right p-b-40">
                                      <h4 className="text-white m-b-5 properties_title">{property.property_title}</h4>
                                      <a
                                          href={ getItemUrl(property) }
                                          className="fs-12 hint-text text-white all-caps">More details...</a>
                                    </div>

                                  </a>
                                </>
                            )
                          })
                        }
                      </div>
                    </section>
                  </>
                  :
                  <>

                    <section className="container-fluid b-t b-white p-0" style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="row">
                        <Skeleton height={350} className="col-md-4" />
                      </div>
                    </section>
                  </>
            }
            {
              showAll == 1 ?
                  <>
                    <section className={" p-b-100 p-t-40"}>
                      <div className="container text-center">
                        <div className="row justify-content-center">
                          <div className={"col-auto text-center"}>
                            <a className={'btn btn-primary btn-design-color p-4 p-t-10 p-b-10'} href={'/properties/all'}>
                              {
                                Checkers.isValidString(showAllLabel) ?
                                    <>{showAllLabel}</>
                                    :
                                    <>Show all</>
                              }
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <></>
            }
          </>
        }
      </>
  )
}