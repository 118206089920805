import React, { Suspense, useContext, useEffect, useState } from 'react';

import {faArrowDown} from '@fortawesome/pro-regular-svg-icons'

import * as appContext from '../../../../components/AppContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {fetchPropertiesAction} from "../../../../redux/actions";
import Checkers from "../../../../helpers/class/Checkers";

import cloneDeep from 'lodash/cloneDeep';
import Skeleton from "react-loading-skeleton";
import ArticleCategoriesContainer from "../../articles/ArticleCategoriesContainer";
import ArticleTypes from "../../articles/ArticleTypes";
import TabsContentWrapper from "../../properties/partials/TabsContentWrapper";
import ServiceContent from "../../property/partials/services/ServiceContent";

interface ServicesSectionProps {
  title?: string
  subtitle?: string
  profile?: any
  limit?: number
  backgroundColor?: string
  darkMode?: number
  app_data?: any
  app?: any
  builder?: any
  isDesktop?: boolean,
  viewType?: string,
  tabbedView?: number|string
}

export default function ServicesSection ( { title, subtitle, limit, backgroundColor, darkMode, app_data, app, builder, isDesktop, viewType, tabbedView } : ServicesSectionProps ) {

  const dispatch = useDispatch()

  const textColor = () => {
    if (darkMode == 1) {
      return "text-light-color"
    }

    return ""
  }

  const [servicesArrayLoading, setServicesArrayLoading] = useState(true)
  const [servicesArray, setServicesArray] = useState([])

  const getTabbedContent = () => {
    let result = []

    result.push({
      tabId: "included",
      tabTitle: 'Included',
      tabContent:
          <ArticleCategoriesContainer
              backgroundColor={backgroundColor}
              textColor={textColor()}
              app_data={app_data}
              app={app}
              builder={builder}
              type={'services'}
              serviceType={'1'}
              inheritedViewType={viewType}
              articlesLimit={limit}
              content={{
                "content": {
                  "title": title,
                  "subtitle": subtitle
                },
                "articles_categories": []
              }}
              isDesktop={isDesktop}
              includeJumbotron={false}
              hideTitle={true}
              itemClassName={'service-item-homepage'}
          />
    })

    result.push({
      tabId: "featured",
      tabTitle: 'On request',
      tabContent:
          <ArticleCategoriesContainer
              backgroundColor={backgroundColor}
              textColor={textColor()}
              app_data={app_data}
              app={app}
              builder={builder}
              type={'services'}
              serviceType={'2'}
              inheritedViewType={viewType}
              articlesLimit={limit}
              content={{
                "content": {
                  "title": title,
                  "subtitle": subtitle
                },
                "articles_categories": []
              }}
              isDesktop={isDesktop}
              includeJumbotron={false}
              hideTitle={true}
              itemClassName={'service-item-homepage'}
          />
    })

    setServicesArrayLoading(false)

    setServicesArray(result)
  }

    useEffect(() => {
        if (servicesArrayLoading) {
            getTabbedContent()
        }
    }, [])

  const viewData = () => {
    if (tabbedView == 1 || tabbedView == '1') {
      return (
          <>
            <TabsContentWrapper data={servicesArray} ReactComponentsArrayLoading={servicesArrayLoading} tabsContentClass={'m-t-40 m-b-40'} backgroundColor={backgroundColor} darkMode={darkMode}/>
          </>
      )
    } else {
      return (
          <>
            <ArticleCategoriesContainer
                backgroundColor={backgroundColor}
                textColor={textColor()}
                app_data={app_data}
                app={app}
                builder={builder}
                type={'services'}
                inheritedViewType={viewType}
                articlesLimit={limit}
                content={{
                  "content": {
                    "title": title,
                    "subtitle": subtitle
                  },
                  "articles_categories": []
                }}
                isDesktop={isDesktop}
                includeJumbotron={false}
                hideTitle={true}
                itemClassName={'service-item-homepage'}
            />
          </>
      )
    }
  }

  return (
      <>
        {
          <>
            {
              Checkers.isValidString(title) || Checkers.isValidString(subtitle) ?
                  <>
                    <section className={" p-b-20 p-t-100 services-container"} style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-sm-8 offset-sm-2">
                              {Checkers.isValidString(title) && <><h2 className={textColor() + " m-t-5 light"}>{title}</h2></>}
                            {Checkers.isValidString(subtitle) && <><h6 className={textColor() + " block-title m-b-0"}>{subtitle}</h6></>}
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <></>
            }
            {viewData()}
          </>
        }
      </>
  )
}