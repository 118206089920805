import React, { Suspense, useEffect, useState } from 'react';

import ReadMoreText from '../../../ReadMoreText';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey, textColor} from "../../../../helpers/MethodHelper";
import Checkers from "../../../../helpers/class/Checkers";

export default function DistinctSection ({ distincts, backgroundColor, darkMode }) {

    const isMobile = useSelector(state => state['appSettings']['isMobile'])

    const numberOfColumns = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_columns')
    );

    const textAlignment = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_text_alignment')
    );

    const sectionTitle = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_title')
    );

    const sectionSubtitle = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_subtitle')
    );

    const sectionBorders = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_border') == 1 ? true : false
    );

    const sectionRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_radius')
    );

    const sectionShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_shadow')
    );

    const textAlignmentClass = textAlignment == 'center' ? 'text-center' : 'text-left'

    const classColumnHandler = (numberOfColumns) => {
        if (distincts.distincts.length<4) {
            return isMobile ? "col-12 m-b-20" : "col m-b-20"
        }

        if (numberOfColumns=="2"){
            return "col-sm-6 col-md-6 m-b-20"
        }
        if (numberOfColumns=="3"){
            return "col-sm-6 col-md-4 m-b-20"
        }
        if (numberOfColumns=="4"){
            return "col-sm-6 col-md-3 m-b-20"
        }
        if (numberOfColumns=="5"){
            return isMobile ? "col-12 m-b-20" : "col m-b-20"
        }
        if (numberOfColumns=="6"){
            return "col-sm-6 col-md-2 m-b-20"
        }

        return "col-sm-6 col-md-3 m-b-20"
    }

    const [classColumn, setClassColumn] = useState('')

    useEffect( () => {
        setClassColumn(classColumnHandler(numberOfColumns))
    }, [numberOfColumns])

    return (
    <>
      <section id={'distincts-section'} className="p-t-100 p-b-100"
               style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : 'transparent'}}
      >

        <div dangerouslySetInnerHTML={{ __html: distincts.title }}/>
          <div className="container">
              <div className=" pb-4 ">
                  <div className="undefined p-l-0 p-r-0">
                      <div
                          className="row justify-content-center w-100 m-0 text-center d-block ">
                          <div
                              className="col-12 col-sm-10 w-100 text-center d-flex flex-column justify-content-center text-left d-block ">
                              {
                                  Checkers.isValidString(sectionTitle) ?
                                      <h2 className={"m-t-5 light " + textColor(darkMode)}>{sectionTitle}</h2>
                                      :
                                      <></>
                              }
                              <p className={"block-title m-t-40 hint-text no-margin justify-content-center text-center d-block "+ textColor(darkMode)}>{sectionSubtitle}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="clearfix row">
                  {
                      distincts.distincts.map((distinct, key) => (
                          <div className={textAlignmentClass + " " + classColumn} key={key}>
                              <div className={"clearfix " + ( sectionBorders ? " border p-4 h-100 " : " " )  + ( Checkers.isValidPositiveNumber(sectionRadius) ? (" border-radius-" + sectionRadius) : " ")}>
                                  <i className={"fa-2x " + distinct.faIcon + " " + textColor(darkMode)}></i>
                                  <p className={textColor(darkMode) + " h6 block-title p-b-5 m-t-10 m-b-10 large "}>{distinct.title}</p>
                                  <div className={"m-b-30 " + textColor(darkMode)}>
                                      <ReadMoreText letters={160} faIcon={distinct.faIcon} title={distinct.title}
                                                    layout={2} text={distinct.description} darkMode={darkMode}/>
                                  </div>
                              </div>
                          </div>
                      ))
                  }
              </div>
          </div>
      </section>
    </>
    )
}