import React, { Suspense, useContext, useEffect, useState } from 'react';

import {faArrowDown} from '@fortawesome/pro-regular-svg-icons'

import * as appContext from '../../../../components/AppContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";

export default function ProfileSection ({ profile }) {

  const profileViewType = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_profile_view_type')
  );

  const isColumned = profileViewType == '1';
  const isCentered = profileViewType != '1';

  const extraClasses = isCentered ? " text-center" : " text-full-alignment";

  return (
    <>
      {
        isColumned == true ?
          <>
            <section className="bg-master-lightest p-b-100 p-t-100">
              <div className="container">
                <p className="block-title hint-text no-margin">{profile.title}</p>
                  <div className="row">
                    <div className="col-sm-5 col-md-4">
                      <h2 className="m-t-5 hidden-xs">{profile.motto}</h2>
                    </div>
                    <div className={"col-sm-7 col-md-8 no-padding xs-p-l-15 xs-p-r-15 d-flex justify-content-center align-items-center " + extraClasses}>
                      <div className={"p-t-0 p-l-35 md-p-l-5 md-p-t-15" + extraClasses} dangerouslySetInnerHTML={{ __html: profile.description }}/>
                    </div>
                  </div>
              </div>
            </section>
          </>
          :
          <>
            <section className=" p-b-100 p-t-100">
              <div className="container text-center">
                <div className="row">
                  <div className="col-sm-8 offset-sm-2">
                    <h6 className="block-title m-b-0">{profile.title}</h6>
                    <h1 className="m-t-5 light">{profile.motto}</h1>
                    <div className={"m-t-20" + extraClasses} dangerouslySetInnerHTML={{ __html: profile.description }}/>
                    <FontAwesomeIcon icon={faArrowDown} className={'fs-24 m-t-20'} style={{ color: "#d1d1d1" }}/>
                  </div>
                </div>
              </div>
            </section>
          </>
      }
    </>
  )
}