import React, {useEffect} from 'react';
import Link from 'next/link';

import ReadMoreText from '../../../ReadMoreText';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import Checkers from "../../../../helpers/class/Checkers";
import {number} from "prop-types";

export default function DestinationsSection ({ destinations, isHomepage }) {


  const getGridArea = (key) => {
    if (key>5) {
      key = (key + 1) % 6;
    }
    // key++;
    // if(key>6){
    //   key = key - (6 * (key/6));
    // // }
    //
    // console.log('debughere', 'key', key, 'mod', positionCheck)

    if (key == 0)
      return 'boxA';
    else if (key == 1)
      return 'boxB';
    else if (key == 2)
      return 'boxC';
    else if (key == 3)
      return 'boxD';
    else if (key == 4)
      return 'boxE';
    else if (key == 5)
      return 'boxF';

    return 'boxUnkown'
  }

  const builderDestinationsBackgroundColor = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_background_color')
  );

  const showDestinationsInHomepage = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_show_in_homepage')
  );

  const destinationsButtonTitle = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_actions_button')
  );

  let classCol = "col-sm-6";
  let itemsPerColumn = 3;

  let destinationColumns = [];
  let currentDestinationColumns = []

  let column = 1;
  let remainder = null;

  const destinationTwoDimension = []

  destinations.destinations.map((destination, key) => {
    let row = Math.floor( key/6 )

    // console.log("destinationHere", destination, column)

    if (destinationTwoDimension[row]==undefined) {
      destinationTwoDimension[row] = []
    }

    (destinationTwoDimension[row]).push(destination)
  })

  destinations.destinations.map((destination, key) => {
    if (isHomepage && key > 5) {
      return
    }
    column = Math.floor(key / itemsPerColumn);
    remainder = key % itemsPerColumn;

    if (column % 2 == 1) {
      if (remainder == 0) {
        classCol = 'col-sm-12';
      } else {
        classCol = 'col-sm-6';
      }
    } else {
      if (remainder == 2) {
        classCol = 'col-sm-12';
      } else {
        classCol = 'col-sm-6';
      }


      // if ( column % 2 == 1 ) {
      //   if ( remainder == 0  ||remainder == 1  ) {
      //     classCol = 'col-sm-6';
      //   } else {
      //     classCol = 'col-sm-12';
      //   }
      // } else {
      //   if ( remainder == 2 ||remainder == 1 ) {
      //     classCol = 'col-sm-6';
      //   } else {
      //     classCol = 'col-sm-12';
      //   }
    }

    destination.className = classCol;

    currentDestinationColumns.push(destination);

    if (currentDestinationColumns.length == 3 && remainder == 2) {
      destinationColumns.push(currentDestinationColumns);
      currentDestinationColumns = [];
    }
  })

  if (currentDestinationColumns.length > 0) {
    destinationColumns.push(currentDestinationColumns);
  }


  return (
      showDestinationsInHomepage == 1 ?
          <>
            {
                !isHomepage &&
                <section key="deistinations_title" className="bg-master-lightest p-b-20 p-t-30">
                  <div key={0} className="container">
                    <div key={1} className="md-p-l-20 md-p-r-20 xs-no-padding">
                      <div key={2} className="row  text-center">
                        <p key={3} className="block-title hint-text no-margin">{destinations.main_title}</p>
                        <div key={4} className="col-sm-12 col-md-12">
                          {
                            destinations.main_subtitle != null && destinations.main_subtitle.length > 0 ?
                                <h2 key={5} className="m-t-5 hidden-xs">{destinations.main_subtitle}</h2>
                                :
                                <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
            }
            <section key="deistinations_items"
                     className={"p-t-50 p-b-50 " + (!Checkers.isHexColor(builderDestinationsBackgroundColor) ? "bg-white" : "")}
                     style={{background: Checkers.isHexColor(builderDestinationsBackgroundColor) ? (builderDestinationsBackgroundColor) : null}}>
              <div key={5} className="container">

                <div key={6} className="panel panel-transparent">
                  {
                    isHomepage ?
                        destinations.title != null && destinations.title.length > 0 ?
                            <div key={7} className="panel-heading">
                              <div key={8}  className="panel-title fs-16">
                                {destinations.title}
                              </div>
                              <div key={9} className="panel-controls">
                                <ul key={10}>
                                  <li key={11} >
                                    <a key={12} href={"/destinations/all"}>{destinationsButtonTitle}</a>
                                    {/*<Link href={'destinations'}>see all</Link>*/}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            :
                            <div key={13} className="panel-heading">
                              <div  key={14} className="panel-title fs-16">
                                {destinations.main_title}
                                {
                                  destinations.main_subtitle != null && destinations.main_subtitle.length > 0 ?
                                      <p>{destinations.main_subtitle}</p>
                                      :
                                      <></>
                                }
                              </div>
                              <div key={15} className="panel-controls">
                                <ul key={16} >
                                  <li key={17} >
                                    <Link key={18} href={"/destinations/all"}>{destinationsButtonTitle}</Link>
                                    {/*<Link href={'destinations'}>see all</Link>*/}
                                  </li>
                                </ul>
                              </div>
                            </div>
                        :
                        <>
                        </>
                  }

                  <div key={19}  className="panel-body p-t-20">

                    <>
                      {
                        isHomepage ?
                            <>
                              <div key={20} className="custom-css-grid m-b-20">
                                {
                                  destinations.destinations.map((destination, key) => {
                                    return (
                                        key < 6 ?
                                            <React.Fragment key={key} >
                                              <div key={"destination_item_" + key} style={{gridArea: getGridArea(key)}}>
                                                <div
                                                    className="design-box-destination card social-card share full-width"
                                                    style={{overflow: "hidden"}}
                                                    // style={{ border: "none", borderRadius: "10px", boxShadow: "0px 0px 8px #CCC", overflow: "hidden" }}
                                                >
                                                  {/*style={'border:none; border-radius:10px; box-shadow: 0px 0px 8px #CCC;'}*/}
                                                  <div className="card-content">
                                                    <Link href={destination.sourcePath}>
                                                      <img alt="Social post" src={destination.image_thumb}/>
                                                      {/*  style="border-radius: 10px 10px 0px 0px;"*/}
                                                    </Link>
                                                  </div>
                                                  <div className="card-footer text-left clearfix">
                                                    <div className="text-uppercase hint-text small-text">
                                                      {destination.properties}+ properties
                                                    </div>
                                                    <p className="bold">
                                                      <Link href={destination.sourcePath}>
                                                        <a className="text-master bold">
                                                          {destination.title}
                                                        </a>
                                                      </Link>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment key={key} />
                                    )
                                  })
                                }
                              </div>
                            </>
                            :
                            <>
                              {
                                  Checkers.isValidArray(destinationTwoDimension) && destinationTwoDimension.map( (destinationsInner, index) => {
                                    return (
                                        <>
                                          <div key={index} className="custom-css-grid m-b-20">
                                            {
                                              destinationsInner.map((destination, key) => {
                                                return (
                                                    <>
                                                      <div key={index+"_"+key} style={{gridArea: getGridArea(key)}}>
                                                        <div
                                                            className="design-box-destination card social-card share full-width"
                                                            style={{overflow: "hidden"}}
                                                            // style={{ border: "none", borderRadius: "10px", boxShadow: "0px 0px 8px #CCC", overflow: "hidden" }}
                                                        >
                                                          {/*style={'border:none; border-radius:10px; box-shadow: 0px 0px 8px #CCC;'}*/}
                                                          <div className="card-content">
                                                            <a href={destination.sourcePath}>
                                                              <img alt="Social post" src={destination.image_thumb}/>
                                                              {/*  style="border-radius: 10px 10px 0px 0px;"*/}
                                                            </a>
                                                          </div>
                                                          <div className="card-footer text-left clearfix">
                                                            <div className="text-uppercase hint-text small-text">
                                                              {destination.properties}+ properties
                                                            </div>
                                                            <p className="bold">
                                                              <a href={destination.sourcePath} className="text-master bold">
                                                                {destination.title}
                                                              </a>
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                )
                                              })
                                            }
                                          </div>
                                        </>
                                    )
                                  })
                              }
                            </>
                      }
                    </>
                  </div>

                </div>

              </div>

            </section>
          </>
          :
          <></>
  )
}