import React, {Suspense, useEffect} from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';
import dynamic from 'next/dynamic';
import ArticleTypes from "../../articles/ArticleTypes";
import Checkers from "../../../../helpers/class/Checkers";
import ArticleCategoriesContainer from "../../articles/ArticleCategoriesContainer";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import SwipeableDrawerFilters from "../../property/content/columned/right/SwipeableDrawerFilters";
import Calendar from "../../../../helpers/class/Calendar";
import PropertiesSection from "./PropertiesSection";
import BlogSection from "./BlogSection";
import ServicesSection from "./ServicesSection";
import {HomepageSections} from "./HomepageSections";

const Header = dynamic( () => import( '../../../Header' ) );
const Jumbotron = dynamic( () => import( '../../../Jumbotron' ), { suspense: true });
const BookingSearchBar = dynamic( () => import( '../BookingSearchBar' ), { suspense: true });
const BottomSections = dynamic( () => import( '../../../BottomSections' ), { suspense: true });
const DistinctSection = dynamic( () => import( './DistinctSection' ), { suspense: true });
const ProfileSection = dynamic( () => import( './ProfileSection' ), { suspense: true });
const DestinationsSection = dynamic( () => import( './DestinationsSection' ), { suspense: true });
const CollectionsSection = dynamic( () => import( './CollectionsSection' ), { suspense: true });


interface IndexSectionsProps {
    isDesktop : any
    html_sections : any
    app_data : any
    head : any
    app : any
    builder : any
    navigation : any
    content : any
    locations : any
    footer : any
    distincts : any
    profile : any
    destinations : any
    collections : any
    isHomepage : any
    isExperiencesPage ?: boolean
    categoriesContentDefault ?: any
    categoriesContentPromoted ?: any
    categoriesContentPromoted2 ?: any
    experienceCategoriesContentDefault ?: any
    experienceCategoriesContentPromoted ?: any
    experienceCategoriesContentPromoted2 ?: any
    htmlSections?: any
}

export default function IndexSections
(
    {
        isDesktop,
        html_sections,
        app_data,
        head,
        app,
        builder,
        navigation,
        content,
        locations,
        footer,
        distincts,
        profile,
        destinations,
        collections,
        isHomepage,
        isExperiencesPage = false,
        categoriesContentDefault,
        categoriesContentPromoted,
        categoriesContentPromoted2,
        experienceCategoriesContentDefault,
        experienceCategoriesContentPromoted,
        experienceCategoriesContentPromoted2,
        htmlSections
    } : IndexSectionsProps
) {

    let header_section = true;
    let jumbotron_section = true;
    let contact_section = true;
    let footer_section = true;

    // if ( html_sections != null ) {
    //   // header_section = false;
    //   // jumbotron_section = false;
    //   // contact_section = false;
    //   // footer_section = false;
    //   // html_sections.forEach( ( obj ) => {
    //   //
    //   //   if ( obj.app_section_type == 'SYSTEM_HEADER' ) {
    //   //     header_section = true;
    //   //   }
    //   //   if ( obj.app_section_type == 'SYSTEM_JUMBOTRON' ) {
    //   //     jumbotron_section = true;
    //   //   }
    //   //   if ( obj.app_section_type == 'SYSTEM_CONTACT' ) {
    //   //     contact_section = true;
    //   //   }
    //   //   if ( obj.app_section_type == 'SYSTEM_FOOTER' ) {
    //   //     footer_section = true;
    //   //   }
    //   // });
    // }

    const contactIcons = {
        phoneIcon: app.showPhoneIconOnMenu,
        chatIcon: app.showChatIconOnMenu,
        mailIcon: app.showMailIconOnMenu,
        phoneIconData: app.menuPhoneIconData,
        chatIconData: app.menuChatIconData,
        mailIconData: app.menuMailIconData
    }

    const isHomePageRedux = useSelector(state => state['appSettings']['isHomepage'])

    const textColor = (dark_text) => {
        if (dark_text != undefined && dark_text != false) {
            return ""
        }

        return "text-light-color"
    }

    const contentTypeHandler = (type) => {
        return Checkers.isValidString(type) && type;
    }


    Calendar.setProps({
        isDesktop,
        html_sections,
        app_data,
        head,
        app,
        builder,
        navigation,
        content,
        locations,
        footer,
        distincts,
        profile,
        destinations,
        collections,
        isHomepage,
        categoriesContentDefault,
        categoriesContentPromoted,
        categoriesContentPromoted2
    });

    const calendarMaterialTheme = Calendar.getCalendarMaterialTheme;

    const calendar = {
        customTheme: calendarMaterialTheme,
    };

    const homepageSections = app.homepageSections

    return (
        <React.Fragment key={'page'}>
            {/*{*/}
            {/*    header_section ?*/}
            {/*        <Suspense>*/}
            {/*            <Header*/}
            {/*                key={'header'}*/}
            {/*                app={app}*/}
            {/*                builder={builder}*/}
            {/*                phone={head.location[0].phone}*/}
            {/*                menu={navigation.menu}*/}
            {/*                partials={navigation.partials}*/}
            {/*                showBookButtonMenu={app.showBookButtonMenu}*/}
            {/*                contactIcons={app.showContactIconsOnMenu ? contactIcons : null}*/}
            {/*                pageTitle={head.data.title}*/}
            {/*                property={isExperiencesPage ? true : false}*/}
            {/*                zIndex={1027}*/}
            {/*            />*/}
            {/*        </Suspense>*/}
            {/*        :*/}
            {/*        <></>*/}
            {/*}*/}
            {
                isHomepage && jumbotron_section ?
                    <>
                        <Suspense>
                            <Jumbotron
                                key={'jubmotron'}
                                withMotto={true}
                                isDesktop={isDesktop}
                                isManager={app_data.is_manager}
                                content={content.details}
                                pageTitle={head.data.title}
                                motto={content.details.motto}
                                album={content.album}
                                designMode={app.designMode}
                                logoDecoration={app.logoDecoration}
                                logoDecorationOriginWidth={app.logoDecorationOriginWidth}
                                logoDecorationOriginHeight={app.logoDecorationOriginHeight}
                                jumbotronHeight={app.jumbotronHeight}
                                jumbotronAutoplay={app.jumbotronAutoplay}
                                jumbotronOverlayOpacity={app.jumbotronOverlayOpacity}
                                jumbotronTextAlign={app.jumbotronTextAlign}
                                jumbotronTextColor={app.jumbotronTextColor}
                                sliderTitleShadow={app.sliderTitleShadow}
                                sliderTitleBold={app.sliderTitleBold}
                                sliderThumbnails={app.sliderThumbnails}
                                video={content.details.hasOwnProperty('video') && content.details.video != null ? content.details.video.length > 0 ? content.details.video : null : null}
                                homeTitle={app.hasOwnProperty('homeTitle') && app.homeTitle != null ? app.homeTitle.length > 0 ? app.homeTitle : null : null}
                                homeSubtitle={app.hasOwnProperty('homeSubtitle') && app.homeSubtitle != null ? app.homeSubtitle.length > 0 ? app.homeSubtitle : null : null}
                                sliderSearchBar={(app.enableBooking && !(app.bookingSearchbarWidth) && !(app.bookingSearchbarPosition))}
                                searchbarRadius={app.searchbarRadius != null ? app.searchbarRadius.length > 0 ? app.searchbarRadius : null : null}
                                searchbarShadow={app.searchbarShadow != null ? app.searchbarShadow.length > 0 ? app.searchbarShadow : null : null}
                                bookingSearchbarWithBedrooms={app.hasOwnProperty('bookingSearchbarWithBedrooms') ? !!app.bookingSearchbarWithBedrooms : false}
                            />
                        </Suspense>
                        {
                            isDesktop && app.enableBooking ?
                                !(app.bookingSearchbarWidth) && app.bookingSearchbarPosition ?
                                    <section className="booking-bar position-relative z-index-2">
                                        <div className="container position-absolute start-50 translate-middle">
                                            <h2 className="hidden">Booking Bar</h2>
                                            <Suspense>
                                                <BookingSearchBar
                                                    key={'BookingSearchBar'}
                                                    isDesktop={isDesktop}
                                                    isManager={app_data.is_manager}
                                                    designMode={app.designMode}
                                                    searchbarThemeColor={app.searchbarThemeColor}
                                                    underSliderContained={true}
                                                    searchbarRadius={app.searchbarRadius != null ? app.searchbarRadius.length > 0 ? app.searchbarRadius.length : null : null}
                                                    searchbarShadow={app.searchbarShadow != null ? app.searchbarShadow.length > 0 ? app.searchbarShadow.length : null : null}
                                                    bookingSearchbarWithBedrooms={app.hasOwnProperty('bookingSearchbarWithBedrooms') ? !!app.bookingSearchbarWithBedrooms : false}
                                                    isHomePage={true}
                                                />
                                            </Suspense>
                                        </div>
                                    </section>
                                    : app.bookingSearchbarWidth ?
                                        <Suspense>
                                            <BookingSearchBar
                                                isDesktop={isDesktop}
                                                key={'BookingSearchBar'}
                                                isManager={app_data.is_manager}
                                                fullWidthBar={true}
                                                designMode={app.designMode}
                                                bookingSearchbarWithBedrooms={app.hasOwnProperty('bookingSearchbarWithBedrooms') ? !!app.bookingSearchbarWithBedrooms : false}
                                                searchbarThemeColor={app.searchbarThemeColor != null ? app.searchbarThemeColor : null}
                                                searchbarBgColor={app.searchbarBgColor != null && app.searchbarBgColor != '' ? app.searchbarBgColor : null}
                                                position={app.bookingSearchbarPosition}
                                                isHomePage={true}
                                            />
                                        </Suspense>
                                        :
                                        <></>
                                :
                                <></>
                        }
                        {
                            !isDesktop ?
                                <>
                                    <SwipeableDrawerFilters key={'SwipeableDrawerFilters'} forHomepage={isHomepage} calendar={calendar}/>
                                </>
                                :
                                <>
                                </>
                        }
                    </>
                    :
                    <></>
            }

            {
                isHomepage ?
                    <HomepageSections
                        app={app}
                        app_data={app_data}
                        builder={builder}
                        isDesktop={isDesktop}
                        isHomepage={isHomepage}
                        categoriesContentPromoted={categoriesContentPromoted}
                        experienceCategoriesContentPromoted={experienceCategoriesContentPromoted}
                        profile={profile}
                        destinations={destinations}
                        categoriesContentPromoted2={categoriesContentPromoted2}
                        experienceCategoriesContentPromoted2={experienceCategoriesContentPromoted2}
                        distincts={distincts}
                        collections={collections}
                        experienceCategoriesContentDefault={experienceCategoriesContentDefault}
                        categoriesContentDefault={categoriesContentDefault}
                        />
                    :
                    <></>
            }

            {
                isHomepage && html_sections != null && html_sections != undefined ?
                    html_sections.map((section, index) => {
                        if (section.app_section_position == 'after_jumbotron') {
                            if (section.app_section_content.length > 0) {
                                return (
                                    <React.Fragment key={'fr' + index} >
                                        <div key={index} dangerouslySetInnerHTML={{__html: section.app_section_content}}/>
                                    </React.Fragment>
                                )
                            }
                        }
                    })
                    :
                    <></>
            }

            {
                !isHomepage && destinations != null && destinations.count > 0
                    ?
                    <Suspense>
                        <DestinationsSection
                            key={'destinations'}
                            destinations={destinations}
                            isHomepage={isHomepage}
                        />
                    </Suspense>
                    :
                    <></>
            }

            {

                isExperiencesPage && experienceCategoriesContentDefault!=null && experienceCategoriesContentDefault.length > 0
                    ?
                    <>
                        {
                            experienceCategoriesContentDefault.map((item, index) => {

                                return (
                                    <React.Fragment key={'fragment' + index} >
                                        <Suspense>
                                            <ArticleCategoriesContainer
                                                key={index}
                                                app_data={app_data}
                                                app={app}
                                                builder={builder}
                                                type={'experiences'}
                                                content={item}
                                                isDesktop={isDesktop}
                                                articlesCategories={null}
                                                categoryIdInhrerited={item.id}
                                                includeJumbotron={false}
                                                includeTitle={!item.hide_title}
                                                itemClassName={'experience-design-box'}
                                            />
                                        </Suspense>
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                    :
                    <></>
            }


            {
                !(homepageSections && Checkers.isValidArray(homepageSections)) && html_sections != null ?
                    <>
                    <div className={'container-fluid overflow-hidden'}>
                        {
                            html_sections.map((section, key) => (
                                section.app_section_content != null ?
                                    section.app_section_content.includes('elfsight-app') ?
                                        section.app_section_content.split('elfsight-app-')[1] != null ?
                                            <ElfsightWidget key={'elfsign-'+key}
                                                            widgetID={section.app_section_content.split('elfsight-app-')[1].split('"></div>')[0]}/>
                                            :
                                            <React.Fragment key={'elfsight-fragment' + key} />
                                        :
                                        <React.Fragment key={'elfsight-fragment' + key} />
                                    :
                                    <React.Fragment key={'elfsight-fragment' + key} />
                            ))
                        }
                    </div>
                    </>

                    :
                    <React.Fragment key={'elfsight-fragment'} />
            }

            {/*<Suspense>*/}
            {/*    <BottomSections*/}
            {/*        footerPaddingClass={!isDesktop ? 'search-xs-p-b-70' : ''}*/}
            {/*        key={'bottom_section'}*/}
            {/*        isDesktop={isDesktop}*/}
            {/*        contactSection={contact_section}*/}
            {/*        footerSection={footer_section}*/}
            {/*        app_data={app_data}*/}
            {/*        app={app}*/}
            {/*        head={head}*/}
            {/*        locations={locations}*/}
            {/*        footer={footer}*/}
            {/*        htmlSections={htmlSections}*/}
            {/*    />*/}
            {/*</Suspense>*/}
        </React.Fragment>
    )
}
