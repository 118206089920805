import React, { Suspense, useContext, useEffect, useState } from 'react';

import {faArrowDown} from '@fortawesome/pro-regular-svg-icons'

import * as appContext from '../../../../components/AppContext';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {fetchPropertiesAction} from "../../../../redux/actions";
import Checkers from "../../../../helpers/class/Checkers";

import cloneDeep from 'lodash/cloneDeep';
import Skeleton from "react-loading-skeleton";
import ArticleCategoriesContainer from "../../articles/ArticleCategoriesContainer";
import ArticleTypes from "../../articles/ArticleTypes";

interface BlogSectionProps {
  title?: string
  subtitle?: string
  profile?: any
  limit?: number
  backgroundColor?: string
  darkMode?: number
  showAll?: number
  showAllLabel?: string
  app_data?: any
  app?: any
  builder?: any
  isDesktop?: boolean,
  viewType?: string
}

export default function BlogSection ( { title, subtitle, limit, backgroundColor, darkMode, showAll, showAllLabel, app_data, app, builder, isDesktop, viewType } : BlogSectionProps ) {

  const textColor = () => {
    if (darkMode == 1) {
      return "text-light-color"
    }

    return ""
  }

  return (
      <>
        {
          <>
            <div id={'blog'}>
            {
              Checkers.isValidString(title) || Checkers.isValidString(subtitle) ?
                  <>
                    <section className={"blog-section p-b-20 p-t-100"}
                             style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="container text-center">
                        <div className="row">
                          <div className="col-sm-12">
                            {Checkers.isValidString(subtitle) && <><h6
                                className={textColor() + " block-title m-b-0"}>{subtitle}</h6></>}
                            {Checkers.isValidString(title) && <><h1
                                className={textColor() + " m-t-5 light"}>{title}</h1></>}
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <></>
            }
            {
              <>
                <ArticleCategoriesContainer
                    backgroundColor={backgroundColor}
                    textColor={textColor()}
                    app_data={app_data}
                    app={app}
                    builder={builder}
                    type={'blog'}
                    inheritedViewType={viewType}
                    articlesLimit={limit}
                    content={{
                      "content": {
                        "title": title,
                        "subtitle": subtitle
                      },
                      "articles_categories": []
                    }}
                    isDesktop={isDesktop}
                    includeJumbotron={false}
                    hideTitle={true}
                />
              </>
            }
            {
              showAll == 1 ?
                  <>
                    <section className={" p-b-100 p-t-40"}
                             style={{background: Checkers.isHexColor(backgroundColor) ? backgroundColor : ''}}>
                      <div className="container text-center">
                        <div className="row justify-content-center">
                          <div className={"col-auto text-center"}>
                            <a className={'btn btn-primary btn-design-color p-4 p-t-10 p-b-10'} href={'/blog'}>
                              {
                                Checkers.isValidString(showAllLabel) ?
                                    <>{showAllLabel}</>
                                    :
                                    <>Show all</>
                              }
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <></>
            }
            </div>
          </>
        }
      </>
  )
}