import React, {Suspense, useEffect} from "react";
import ArticleCategoriesContainer from "../../articles/ArticleCategoriesContainer";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import ProfileSection from "./ProfileSection";
import DestinationsSection from "./DestinationsSection";
import PropertiesSection from "./PropertiesSection";
import DistinctSection from "./DistinctSection";
import CollectionsSection from "./CollectionsSection";
import ServicesSection from "./ServicesSection";
import BlogSection from "./BlogSection";
import Checkers from "../../../../helpers/class/Checkers";

interface HomepageSectionsProps {
    app: any
    app_data: any
    builder: any
    isDesktop: boolean
    isHomepage: boolean
    categoriesContentPromoted: any
    experienceCategoriesContentPromoted: any
    profile: any
    destinations: any
    categoriesContentPromoted2: any
    experienceCategoriesContentPromoted2: any
    distincts: any
    collections: any
    experienceCategoriesContentDefault: any
    categoriesContentDefault: any
}

export const HomepageSections = (
    {
        app,
        app_data,
        builder,
        isDesktop,
        isHomepage,
        categoriesContentPromoted,
        experienceCategoriesContentPromoted,
        profile,
        destinations,
        categoriesContentPromoted2,
        experienceCategoriesContentPromoted2,
        distincts,
        collections,
        experienceCategoriesContentDefault,
        categoriesContentDefault
    } : HomepageSectionsProps ) => {

    const showExperiencesSection = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_experiences_show_in_homepage')
    );

    const showProfileSection = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_profile_show_in_homepage')
    );

    const showPropertiesSection = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_featured_properties_enabled')
    );

    const showDistincsSection = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_distincts_show_in_homepage')
    );

    const propertiesSectionTitle = app.builder.builder_homepage_featured_properties_title

    const propertiesSectionDescription = app.builder.builder_homepage_featured_properties_description

    const propertiesSectionFeaturedLimit = app.builder.builder_homepage_featured_properties_number

    const propertiesSectionSectionColor = app.builder.builder_homepage_featured_properties_section_color

    const propertiesSectionDarkMode = app.builder.builder_homepage_featured_properties_dark_mode

    const propertiesSectionShowAllButton = app.builder.builder_homepage_featured_properties_show_all_button

    const propertiesSectionShowAllButtonLabel = app.builder.builder_homepage_featured_properties_show_all_button_label

    const distinctsBackgroundColor = app.builder.builder_homepage_distincts_background_color

    const distinctsDarkMode = app.builder.builder_homepage_distincts_dark_mode

    const showServicesSection = app.builder.builder_homepage_services_enabled

    const servicesSectionTitle = app.builder.builder_homepage_services_title

    const servicesSectionDescription = app.builder.builder_homepage_services_description

    const servicesSectionLimit = app.builder.builder_homepage_services_number

    const servicesSectionSectionColor = app.builder.builder_homepage_services_color

    const servicesSectionDarkMode = app.builder.builder_homepage_services_dark_mode

    const servicesSectionViewType = (app.servicesViewType).toString()

    const servicesSectionTabbedView = app.builder.builder_homepage_services_tabs

    const showBlogSection = app.builder.builder_homepage_blog_enabled

    const blogSectionTitle = app.builder.builder_homepage_blog_title

    const blogSectionDescription = app.builder.builder_homepage_blog_description

    const blogSectionFeaturedLimit = app.builder.builder_homepage_blog_post_number

    const blogSectionSectionColor = app.builder.builder_homepage_blog_section_color

    const blogSectionDarkMode = app.builder.builder_homepage_blog_dark_mode

    const blogSectionShowAllButton = app.builder.builder_homepage_blog_show_all_button

    const blogSectionShowAllButtonLabel = app.builder.builder_homepage_blog_show_all_button_label

    const blogSectionViewType = app.builder.builder_homepage_blog_post_view_type

    const collectionItemsBtnLabel = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_experiences_btn_label')
    );

    const homepageSectionsEnabled = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_enable_sections') == 1
    );

    const homepageSections = app.homepageSections

    return (
        <>
            {
                homepageSections && Checkers.isValidArray(homepageSections)?
                    <>
                        {
                            homepageSections.map( (section) => {
                                if (!section.isActive){
                                    return (
                                        <React.Fragment key={'key-section'}/>
                                    )
                                }

                                switch (section.type) {
                                    case 'SYSTEM_HOMEPAGE_CATEGORIES_PROMOTED': {
                                        return (
                                            <React.Fragment key={'key-' + section.type} >
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && categoriesContentPromoted != null && categoriesContentPromoted.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                categoriesContentPromoted.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <Suspense key={'categories-content-fragment-' + index}>
                                                                                <ArticleCategoriesContainer
                                                                                    key={'categories-content-container-' + index}
                                                                                    app_data={app_data}
                                                                                    app={app}
                                                                                    builder={builder}
                                                                                    type={'category'}
                                                                                    content={item}
                                                                                    isDesktop={isDesktop}
                                                                                    articlesCategories={null}
                                                                                    categoryIdInhrerited={item.id}
                                                                                    includeJumbotron={false}
                                                                                    includeTitle={!item.hide_title}
                                                                                />
                                                                            </Suspense>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_EXPERIENCES_PROMOTED': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {

                                                    showExperiencesSection == 1 && isHomepage && experienceCategoriesContentPromoted != null && experienceCategoriesContentPromoted.length > 0
                                                        ?
                                                        <>
                                                            {/*{*/}
                                                            {/*    experienceCategoriesContentPromoted.map((item, index) => {*/}

                                                            {/*        return (*/}
                                                            {/*            <React.Fragment key={'fragment' + index} >*/}
                                                            {/*                <Suspense>*/}
                                                            {/*                    <ArticleCategoriesContainer*/}
                                                            {/*                        key={index}*/}
                                                            {/*                        app_data={app_data}*/}
                                                            {/*                        app={app}*/}
                                                            {/*                        builder={builder}*/}
                                                            {/*                        type={'experiences'}*/}
                                                            {/*                        content={item}*/}
                                                            {/*                        isDesktop={isDesktop}*/}
                                                            {/*                        articlesCategories={null}*/}
                                                            {/*                        categoryIdInhrerited={item.id}*/}
                                                            {/*                        includeJumbotron={false}*/}
                                                            {/*                        includeTitle={!item.hide_title}*/}
                                                            {/*                    />*/}
                                                            {/*                </Suspense>*/}
                                                            {/*            </React.Fragment>*/}
                                                            {/*        )*/}
                                                            {/*    })*/}
                                                            {/*}*/}
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_PROFILE': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && showProfileSection == 1 && profile &&
                                                    <Suspense>
                                                        <ProfileSection
                                                            key={'overview_profile'}
                                                            profile={profile}
                                                        />
                                                    </Suspense>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_DESTINATIONS': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    destinations != null && destinations.count > 0
                                                        ?
                                                        <Suspense>
                                                            <DestinationsSection
                                                                key={'destinations'}
                                                                destinations={destinations}
                                                                isHomepage={isHomepage}
                                                            />
                                                        </Suspense>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_PROPERTIES': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && showPropertiesSection == 1 && profile &&
                                                    <Suspense>
                                                        <PropertiesSection
                                                            key={'properties_section'}
                                                            title={propertiesSectionTitle}
                                                            subtitle={propertiesSectionDescription}
                                                            limit={propertiesSectionFeaturedLimit}
                                                            backgroundColor={propertiesSectionSectionColor}
                                                            darkMode={propertiesSectionDarkMode}
                                                            showAll={propertiesSectionShowAllButton}
                                                            showAllLabel={propertiesSectionShowAllButtonLabel}
                                                        />
                                                    </Suspense>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_CATEGORIES_PROMOTED_2': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && categoriesContentPromoted2 != null && categoriesContentPromoted2.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                categoriesContentPromoted2.map((item, index) => {
                                                                    return (
                                                                        <React.Fragment
                                                                            key={'categories-content-promoted2-fragment-' + index}>
                                                                            <Suspense>
                                                                                <ArticleCategoriesContainer
                                                                                    key={'categories-content-promoted2-container-' + index}
                                                                                    app_data={app_data}
                                                                                    app={app}
                                                                                    builder={builder}
                                                                                    type={'category'}
                                                                                    content={item}
                                                                                    isDesktop={isDesktop}
                                                                                    articlesCategories={null}
                                                                                    categoryIdInhrerited={item.id}
                                                                                    includeJumbotron={false}
                                                                                    includeTitle={!item.hide_title}
                                                                                />
                                                                            </Suspense>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_EXPERIENCES_PROMOTED_2': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {

                                                    isHomepage && showExperiencesSection == 1 && experienceCategoriesContentPromoted2 != null && experienceCategoriesContentPromoted2.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                experienceCategoriesContentPromoted2.map((item, index) => {

                                                                    return (
                                                                        <React.Fragment
                                                                            key={'categories-content-promoted1-fragment-' + index}>
                                                                            <Suspense>
                                                                                <ArticleCategoriesContainer
                                                                                    key={'categories-content-promoted1-container-' + index}
                                                                                    app_data={app_data}
                                                                                    app={app}
                                                                                    builder={builder}
                                                                                    type={'experiences'}
                                                                                    content={item}
                                                                                    isDesktop={isDesktop}
                                                                                    articlesCategories={null}
                                                                                    categoryIdInhrerited={item.id}
                                                                                    includeJumbotron={false}
                                                                                    includeTitle={!item.hide_title}
                                                                                />
                                                                            </Suspense>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_DISTINCT_SECTIONS': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && showDistincsSection == 1 && distincts && distincts.distincts.length > 0
                                                        ?
                                                        <Suspense>
                                                            <DistinctSection
                                                                key={'distincts'}
                                                                distincts={distincts}
                                                                backgroundColor={distinctsBackgroundColor}
                                                                darkMode={distinctsDarkMode}
                                                            />
                                                        </Suspense>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_COLLECTIONS_DEFAULT': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && collections && collections.count > 0
                                                        ?
                                                        <Suspense>
                                                            <CollectionsSection
                                                                key={'collections'}
                                                                app={app}
                                                                builder={builder}
                                                                app_data={app_data}
                                                                enableReadMore={false}
                                                                collectionsList={collections}
                                                            />
                                                        </Suspense>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_EXPERIENCES_DEFAULT': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {

                                                    isHomepage && showExperiencesSection == 1 && experienceCategoriesContentDefault != null && experienceCategoriesContentDefault.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                experienceCategoriesContentDefault.map((item, index) => {

                                                                    return (
                                                                        <React.Fragment
                                                                            key={'exp-categories-content-fragment-' + index}>
                                                                            <Suspense>
                                                                                <ArticleCategoriesContainer
                                                                                    key={'exp-categories-content-container-' + index}
                                                                                    app_data={app_data}
                                                                                    app={app}
                                                                                    builder={builder}
                                                                                    type={'experiences'}
                                                                                    content={item}
                                                                                    isDesktop={isDesktop}
                                                                                    articlesCategories={null}
                                                                                    categoryIdInhrerited={item.id}
                                                                                    includeJumbotron={false}
                                                                                    includeTitle={!item.hide_title}
                                                                                    itemClassName={'experience-design-box'}
                                                                                    showAllLabel={collectionItemsBtnLabel}
                                                                                />
                                                                            </Suspense>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_SERVICES': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && showServicesSection == 1 &&
                                                    <Suspense>
                                                        <ServicesSection
                                                            key={'services_section'}
                                                            title={servicesSectionTitle}
                                                            subtitle={servicesSectionDescription}
                                                            limit={servicesSectionLimit}
                                                            backgroundColor={servicesSectionSectionColor}
                                                            darkMode={servicesSectionDarkMode}
                                                            viewType={servicesSectionViewType}
                                                            tabbedView={servicesSectionTabbedView}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            isDesktop={isDesktop}
                                                        />
                                                    </Suspense>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_CATEGORIES_DEFAULT': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && categoriesContentDefault != null && categoriesContentDefault.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                categoriesContentDefault.map((item, index) => {
                                                                    return (
                                                                        <React.Fragment key={'fragment' + index}>
                                                                            <Suspense>
                                                                                <ArticleCategoriesContainer
                                                                                    key={'ccd-' + index}
                                                                                    app_data={app_data}
                                                                                    app={app}
                                                                                    builder={builder}
                                                                                    type={'category'}
                                                                                    content={item}
                                                                                    isDesktop={isDesktop}
                                                                                    articlesCategories={null}
                                                                                    categoryIdInhrerited={item.id}
                                                                                    includeJumbotron={false}
                                                                                    includeTitle={!item.hide_title}
                                                                                />
                                                                            </Suspense>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_BLOG': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                {
                                                    isHomepage && showBlogSection == 1 &&
                                                    <Suspense>
                                                        <BlogSection
                                                            key={'blog_section'}
                                                            title={blogSectionTitle}
                                                            subtitle={blogSectionDescription}
                                                            limit={blogSectionFeaturedLimit}
                                                            backgroundColor={blogSectionSectionColor}
                                                            darkMode={blogSectionDarkMode}
                                                            showAll={blogSectionShowAllButton}
                                                            showAllLabel={blogSectionShowAllButtonLabel}
                                                            viewType={blogSectionViewType}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            isDesktop={isDesktop}
                                                        />
                                                    </Suspense>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                    case 'SYSTEM_HOMEPAGE_HTML': {
                                        return (
                                            <React.Fragment key={'key-' + section.type}>
                                                {
                                                    (section.hasOwnProperty('urlSlug') && Checkers.isValidString(section.urlSlug)) ?
                                                        <div id={section.urlSlug}></div>
                                                        : <></>
                                                }
                                                <div dangerouslySetInnerHTML={{__html: section.content}}/>
                                            </React.Fragment>
                                        )
                                    }
                                    default: {
                                        return (
                                            <React.Fragment key={'key-' + section.type} >
                                            </React.Fragment>
                                        )
                                    }
                                }
                            })
                        }
                    </>
                    :
                    <>
                        {

                            showExperiencesSection == 1 && isHomepage && experienceCategoriesContentPromoted != null && experienceCategoriesContentPromoted.length > 0
                                ?
                                <>
                                    {/*{*/}
                                    {/*    experienceCategoriesContentPromoted.map((item, index) => {*/}

                                    {/*        return (*/}
                                    {/*            <React.Fragment key={'fragment' + index} >*/}
                                    {/*                <Suspense>*/}
                                    {/*                    <ArticleCategoriesContainer*/}
                                    {/*                        key={index}*/}
                                    {/*                        app_data={app_data}*/}
                                    {/*                        app={app}*/}
                                    {/*                        builder={builder}*/}
                                    {/*                        type={'experiences'}*/}
                                    {/*                        content={item}*/}
                                    {/*                        isDesktop={isDesktop}*/}
                                    {/*                        articlesCategories={null}*/}
                                    {/*                        categoryIdInhrerited={item.id}*/}
                                    {/*                        includeJumbotron={false}*/}
                                    {/*                        includeTitle={!item.hide_title}*/}
                                    {/*                    />*/}
                                    {/*                </Suspense>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*        )*/}
                                    {/*    })*/}
                                    {/*}*/}
                                </>
                                :
                                <></>
                        }
                        {
                            isHomepage && categoriesContentPromoted != null && categoriesContentPromoted.length > 0
                                ?
                                <>
                                    {
                                        categoriesContentPromoted.map((item, index) => {
                                            return (
                                                <React.Fragment key={'fragment' + index}>
                                                    <Suspense>
                                                        <ArticleCategoriesContainer
                                                            key={'ccp-' + index}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            type={'category'}
                                                            content={item}
                                                            isDesktop={isDesktop}
                                                            articlesCategories={null}
                                                            categoryIdInhrerited={item.id}
                                                            includeJumbotron={false}
                                                            includeTitle={!item.hide_title}
                                                        />
                                                    </Suspense>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }

                        {
                            isHomepage && showProfileSection == 1 && profile &&
                            <Suspense>
                                <ProfileSection
                                    key={'overview_profile'}
                                    profile={profile}
                                />
                            </Suspense>
                        }

                        {
                            destinations != null && destinations.count > 0
                                ?
                                <Suspense>
                                    <DestinationsSection
                                        key={'destinations-default'}
                                        destinations={destinations}
                                        isHomepage={isHomepage}
                                    />
                                </Suspense>
                                :
                                <></>
                        }

                        {
                            isHomepage && showPropertiesSection == 1 && profile &&
                            <Suspense>
                                <PropertiesSection
                                    key={'properties_section'}
                                    title={propertiesSectionTitle}
                                    subtitle={propertiesSectionDescription}
                                    limit={propertiesSectionFeaturedLimit}
                                    backgroundColor={propertiesSectionSectionColor}
                                    darkMode={propertiesSectionDarkMode}
                                    showAll={propertiesSectionShowAllButton}
                                    showAllLabel={propertiesSectionShowAllButtonLabel}
                                />
                            </Suspense>
                        }

                        {
                            isHomepage && categoriesContentPromoted2 != null && categoriesContentPromoted2.length > 0
                                ?
                                <>
                                    {
                                        categoriesContentPromoted2.map((item, index) => {
                                            return (
                                                <React.Fragment key={'fr' + index}>
                                                    <Suspense>
                                                        <ArticleCategoriesContainer
                                                            key={'ccp2-' + index}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            type={'category'}
                                                            content={item}
                                                            isDesktop={isDesktop}
                                                            articlesCategories={null}
                                                            categoryIdInhrerited={item.id}
                                                            includeJumbotron={false}
                                                            includeTitle={!item.hide_title}
                                                        />
                                                    </Suspense>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }

                        {

                            isHomepage && showExperiencesSection == 1 && experienceCategoriesContentPromoted2 != null && experienceCategoriesContentPromoted2.length > 0
                                ?
                                <>
                                    {
                                        experienceCategoriesContentPromoted2.map((item, index) => {

                                            return (
                                                <React.Fragment key={'fragment' + index}>
                                                    <Suspense>
                                                        <ArticleCategoriesContainer
                                                            key={'eccp2- ' + index}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            type={'experiences'}
                                                            content={item}
                                                            isDesktop={isDesktop}
                                                            articlesCategories={null}
                                                            categoryIdInhrerited={item.id}
                                                            includeJumbotron={false}
                                                            includeTitle={!item.hide_title}
                                                        />
                                                    </Suspense>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }

                        {
                            isHomepage && showDistincsSection == 1 && distincts && distincts.distincts.length > 0
                                ?
                                <Suspense>
                                    <DistinctSection
                                        key={'distincts'}
                                        distincts={distincts}
                                        backgroundColor={distinctsBackgroundColor}
                                        darkMode={distinctsDarkMode}
                                    />
                                </Suspense>
                                :
                                <></>
                        }

                        {
                            isHomepage && collections && collections.count > 0
                                ?
                                <Suspense>
                                    <CollectionsSection
                                        key={'collections'}
                                        app={app}
                                        builder={builder}
                                        app_data={app_data}
                                        enableReadMore={false}
                                        collectionsList={collections}
                                    />
                                </Suspense>
                                :
                                <></>
                        }

                        {

                            isHomepage && showExperiencesSection == 1 && experienceCategoriesContentDefault != null && experienceCategoriesContentDefault.length > 0
                                ?
                                <>
                                    {
                                        experienceCategoriesContentDefault.map((item, index) => {

                                            return (
                                                <React.Fragment key={'fragment' + index}>
                                                    <Suspense>
                                                        <ArticleCategoriesContainer
                                                            key={'eccp- ' + index}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            type={'experiences'}
                                                            content={item}
                                                            isDesktop={isDesktop}
                                                            articlesCategories={null}
                                                            categoryIdInhrerited={item.id}
                                                            includeJumbotron={false}
                                                            includeTitle={!item.hide_title}
                                                            itemClassName={'experience-design-box'}
                                                        />
                                                    </Suspense>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }

                        {
                            isHomepage && showServicesSection == 1 &&
                            <Suspense>
                                <ServicesSection
                                    key={'services_section'}
                                    title={servicesSectionTitle}
                                    subtitle={servicesSectionDescription}
                                    limit={servicesSectionLimit}
                                    backgroundColor={servicesSectionSectionColor}
                                    darkMode={servicesSectionDarkMode}
                                    viewType={servicesSectionViewType}
                                    tabbedView={servicesSectionTabbedView}
                                    app_data={app_data}
                                    app={app}
                                    builder={builder}
                                    isDesktop={isDesktop}
                                />
                            </Suspense>
                        }

                        {
                            isHomepage && categoriesContentDefault != null && categoriesContentDefault.length > 0
                                ?
                                <>
                                    {
                                        categoriesContentDefault.map((item, index) => {
                                            return (
                                                <React.Fragment key={'fragment' + index}>
                                                    <Suspense>
                                                        <ArticleCategoriesContainer
                                                            key={'cc- ' + index}
                                                            app_data={app_data}
                                                            app={app}
                                                            builder={builder}
                                                            type={'category'}
                                                            content={item}
                                                            isDesktop={isDesktop}
                                                            articlesCategories={null}
                                                            categoryIdInhrerited={item.id}
                                                            includeJumbotron={false}
                                                            includeTitle={!item.hide_title}
                                                        />
                                                    </Suspense>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </>
                                :
                                <></>
                        }

                        {
                            isHomepage && showBlogSection == 1 &&
                            <Suspense>
                                <BlogSection
                                    key={'blog_section'}
                                    title={blogSectionTitle}
                                    subtitle={blogSectionDescription}
                                    limit={blogSectionFeaturedLimit}
                                    backgroundColor={blogSectionSectionColor}
                                    darkMode={blogSectionDarkMode}
                                    showAll={blogSectionShowAllButton}
                                    showAllLabel={blogSectionShowAllButtonLabel}
                                    viewType={blogSectionViewType}
                                    app_data={app_data}
                                    app={app}
                                    builder={builder}
                                    isDesktop={isDesktop}
                                />
                            </Suspense>
                        }
                    </>
            }
        </>
    )
}